@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
    overflow-x: hidden;
    color: black;
    font-family: "Quicksand", sans-serif;
}

.search-box {
    padding: 0 10px;
    display: flex;
}

.search-btn {
    color: white;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 2s;
}

.search-input {
    border: none;
    background: none;
    outline: none;
    display: flex;
    padding: 0;
    font-size: 20px;
    transition: 0.4s;
    width: 0;
    overflow: hidden;
}

.search-box:hover>.search-input {
    width: 350px;
    padding: 0 7px;
    transition: 0.4s;
}